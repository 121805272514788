import React from "react";
import Layout from "../../components/Layout";

import TagsListPage from "../../components/TagsListPage";

const AllTagsPage = () => (
  <Layout>
    <div className="section homepage">
      <div className="columns">
        <div className="column is-10 is-offset-1">
          <TagsListPage />
        </div>
      </div>
    </div>
  </Layout>
);

export default AllTagsPage;
