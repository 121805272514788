import React from "react";
// import PropTypes from 'prop-types'

// dobijem ove tagove iz Mergera
const allTags = [
  { name: "Tower Defense", score: 94 },
  { name: "Strategy", score: 72 },
  { name: "Defense", score: 58 },
  { name: "Upgrades", score: 22 },
  { name: "Upgrade", score: 11 },
  { name: "Incremental", score: 10 },
  { name: "Games of 2015", score: 9 },
  { name: "Mouse Only", score: 8 },
  { name: "Idle", score: 8 },
  { name: "Games of 2014", score: 7 },
  { name: "Fantasy", score: 6 },
  { name: "Space", score: 5 },
  { name: "Zombie", score: 5 },
  { name: "Pixel", score: 5 },
  { name: "HTML5", score: 4 },
  { name: "Unity", score: 4 },
  { name: "Magic", score: 4 },
  { name: "Alien", score: 3 },
  { name: "Games of 2018", score: 3 },
  { name: "Online Save", score: 3 },
  { name: "Quest", score: 3 },
  { name: "Games of 2016", score: 3 },
  { name: "Monster", score: 2 },
  { name: "Arcade", score: 2 },
  { name: "Animal", score: 2 },
  { name: "War", score: 2 },
  { name: "Military", score: 2 },
  { name: "Multiplayer", score: 2 },
  { name: "Fire", score: 2 },
  { name: "Action", score: 2 },
  { name: "Clicker", score: 2 },
  { name: "Pirate", score: 2 },
  { name: "Shooter", score: 2 },
  { name: "Shooting", score: 2 },
  { name: "3D", score: 2 },
  { name: "Science Fiction", score: 1 },
  { name: "Chess", score: 1 },
  { name: "RTS", score: 1 },
  { name: "MMO", score: 1 },
  { name: "Isometric", score: 1 },
  { name: "Game of the Year", score: 1 },
  { name: "Balloon", score: 1 },
  { name: "Artillery", score: 1 },
  { name: "Dinosaur", score: 1 },
  { name: "Survival", score: 1 },
  { name: "Fighting", score: 1 },
  { name: "Maze", score: 1 },
  { name: "Island", score: 1 },
  { name: "Game Jams", score: 1 },
  { name: "Puzzle-Skill", score: 1 },
  { name: "Bug", score: 1 },
  { name: "Student Developed", score: 1 },
  { name: "Top Down", score: 1 },
  { name: "Alien Shooting", score: 1 },
  { name: "Food", score: 1 },
  { name: "Retro", score: 1 },
  { name: "Mouse", score: 1 },
  { name: "Robot", score: 1 },
  { name: "Match 3", score: 1 },
  { name: "Army", score: 1 },
  { name: "Airplane", score: 1 },
  { name: "Tank", score: 1 },
];

// // sort tags by name, not by score
// allTags.sort(function (a, b) {
//   var textA = a.name.toLowerCase();
//   var textB = b.name.toLowerCase();
//   return textA < textB ? -1 : textA > textB ? 1 : 0;
// });

const TagsList = ({ data }) => (
  <div className="tag-page-max-width content">
    <h3>All Game Tags:</h3>
    <div className="tag-page-box">
      {allTags.map((el, i) => (
        <div className="tag-page-one" key={i}>
          <span className="tag-page-text">
            {el.name} <span className="tag-page-number">- {el.score}</span>
          </span>
          {/* <span className="tag-number">{el.score}</span> */}
        </div>
      ))}
    </div>
  </div>
);

// TagsList.propTypes = {
//   data: PropTypes.arrayOf(
//     PropTypes.shape({
//       plan: PropTypes.string,
//       price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
//       description: PropTypes.string,
//       items: PropTypes.array,
//     })
//   ),
// }

export default TagsList;
